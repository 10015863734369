@font-face{
	font-family: "Ubuntu";
	src: url(../webfonts/Ubuntu-Regular.ttf) format("truetype");
}

@font-face{
	font-family: "Source Sans Pro Regular";
	src: url(../webfonts/SourceSansPro-Regular.ttf), url(../webfonts/SourceSansPro-Regular.eot) format("eot");
}


body{
	background-color: #333;		/*	dark, fallback	*/
	/*background-color: #fafafa;	/*	light, fallback	*/
	color: #fff;		/* dark	*/
	/*color: #333;		/*	light	*/
	margin:0;
	line-height:2em;	/*	responsive	*/
}

body {
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

div{}
	.subsection{
		margin-bottom:1em;		/*	responsive	*/
		line-height:1.44em;		/*	responsive	*/
	}
	.subsection:first-child{
		font-weight:bold;
	}

h1, h2, h3, h4, h5, h6{
	font-family: "Source Sans Pro Regular", sans-serif;
}
	h1{font-size:3em;}						/*	responsive	*/
	.sectionTitle{
		font-weight: bold;
		margin-bottom:9px;
		border-bottom:1px solid #fafafa;	/*	light	*/
		/*border-bottom:1px solid #000;		/*	dark	*/

	}
	.sectionTitleMinor{
		padding-bottom:2px;
		margin-bottom:9px;
		font-size:2em;						/*	responsive	*/
		border-bottom:1px solid #fafafa;	/*	light	*/
		/*border-bottom:1px solid #000;		/*	dark	*/
	}
p{
	font-size: 1.1em;					/*	responsive	*/
}
	.bodyText{margin:0;}
	.smallText{font-size:0.64em;}
a{
	color: #fff;		/* dark	*/
	/*color: #333;		/*	light	*/
}
	.noUnderline{
		text-decoration:none;
	}
span{}
	.subtitle{font-style: italic;}

table{

}
tr{}
td{
	padding: 4px;
}
	.td-empty{width:100%;}
	.td-content{white-space: nowrap;}

/*	Classes	*/
.defaultAnimation{
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
}
.logoAnimation{
	-webkit-transition: all 400ms ease-in-out;
	-moz-transition: all 400ms ease-in-out;
	-o-transition: all 400ms ease-in-out;
	transition: all 400ms ease-in-out;
}
	#v3_x5F__x28_20230412_x29_{
	}
	#v3_x5F__x28_20230412_x29_:hover{
	}
	.logoAnimation{
		fill: #fff;		/* dark	*/
		/*fill: #333;		/*	light	*/
		stroke: transparent;		/* dark	*/
		/*stroke: #333;		/*	light	*/
		stroke-width: 4px;
	}
	.logoAnimation:hover{
		fill:transparent;
		stroke: #fff;
		/* animation: drawLogo 3.5s infinite; */
		
	}
	@keyframes drawLogo{
		50% {
		  stroke-dashoffset: 0;
		}		
	}

.floatLeft{float:left;}
.floatRight{float:right;}
.width100{
	width:100%;
}
.inline{
	display: inline;
}
.textAlignLeft{
	text-align:left;
}
.textAlignCenter{
	text-align: center;
}
.textAlignRight{
	text-align:right;
}


.linkedin{
	color: #fff;			/*	dark	*/
	/*color: #0077b5;		/*	light	*/
}
	.linkedin:hover{
		color: #0077b5;	/*	dark	*/
		/*color: #fff;		/*	light	*/
		}
.behance{
	color: #fff;			/*	dark	*/
	/*color: #0057ff;		/*	light	*/
}
	.behance:hover{
		color: #0057ff;	/*	dark	*/
		/*color: #fff;		/*	light	*/
	}
.github{
	color: #fafafa;		/*	dark	*/
	/*color: #333;			/*	light	 */
}
	.github:hover{
		color: #6cc644;	/*	dark	*/
		/*color: #f5f5f5;	/*	light	 */
	}
.banner{
	padding:9px 0;
}
.bannerButton{
	text-decoration: underline;
	/*	border-radius: 9px;
	border: 1px solid #fff;		/*	dark	*/
	/*border: 1px solid #333;	/*	light	*/
}
	.bannerButton:hover{
		cursor: pointer;
	}

.internalLink{
	color:#fff;		/*	dark	*/
	/*color:#333;		/*	light	*/
}
	.internalLink:hover{
		color:#46c;		/*	dark	*/	
		/*color:#4c6;		/*	light	*/
	}

/* Elements */
#header{

}
#navigationBar{
	height:40px;		/*	responsive	*/
}
#bgContainer{
	position: absolute;
	z-index:-100;
	width:100%;
    height:100%;
    overflow:hidden;
}
	#bgBackdrop{
		position: absolute;
		z-index:-101;
		width:100%;
		height:100%;
	}
	#bgTexture{
		position:absolute;
		z-index:-102;
		width:100%;
		height:100%;
		background-image: url('../public/overlay.png');
	}
	#bgVideo{
		position:relative;
		z-index:-103;
		min-width: 100%;
		min-height: 100%;
		overflow: hidden;
	}
	#bgVideoFallback{
		position:relative;
		z-index:-103;
		min-width: 100%;
		min-height: 100%;
		overflow: hidden;
		display: none;
	}
	/* #bgStonkMountain{
		position: absolute;
		z-index:-101;
		bottom:0;
		width:100%;
	} */
#footer{
	z-index:100;
	background: #4466cc;		/*	dark	*/
	/*background: #44cc66;		/*	light	*/
}

#bgBackdrop {
	background-color: rgba(255, 255, 255, 0.09);	/*	fall-back	*/
}
@supports (backdrop-filter: blur()) {
	#bgBackdrop {
		background-color: transparent;
		/* -webkit-backdrop-filter: blur(10px);
		backdrop-filter: blur(10px); */
	}
}

#footer{
	position: absolute;
	bottom:0;
	width:100%;
}
	.footerText{
		font-size:0.81em;		/*	responsive	*/
	}
	.footer-svg{
		max-width:9	px;			/*	responsive	*/
		max-height:16px;		/*	responsive	*/
	}

.fullPageSingleContent{
	max-width:900px;
	margin:auto;
	padding: 2%;			/*	responsive	*/
}
	#container-Home{
	}
	#container-Error{
	}
.fullPagePartialContent{
	position: relative;
	padding: 2%;				/*	responsive	*/
}
	#container-Aboot{

	}
.fullPagePageContent{
	position: relative;
	/* height:100%;
	width:100%; */
	padding: 2%;			/*	responsive	*/
	/* background-color: #fff; */
}
	#container-Search{

	}
	#container-Content{

	}

/* @1280px, 720px: delete a lot of footer information (in fact delete most footer information and add it in if it is desktop) */
/* @mobile disable video bg; and responsive rule sets */