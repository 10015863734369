.contentCard{

	width:100%;
	height:100%;
	margin-bottom: 4px;
	border-radius: 9px;
	padding: 9px;
	background-color: rgba(255, 255, 255, 0.09);

}
	.contentCard:hover{
		background-color: rgba(255, 255, 255, 0.25);	
	}

.contnetCardTagContainer{
	margin-bottom:0;
}
.contentCardTag{
	
	border-radius:4px;
	padding:4px;
	background-color: rgba(196, 196, 196, 0.36);

}
	.contentCardTag:hover{
		background-color: rgba(196, 196, 196, 0.49);
	}

.contentDatePublished{
	font-style:italic;
	margin-bottom:0;
}